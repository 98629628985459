import { render, staticRenderFns } from "./FirstStep.vue?vue&type=template&id=49715084&scoped=true"
import script from "./FirstStep.vue?vue&type=script&lang=js"
export * from "./FirstStep.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49715084",
  null
  
)

export default component.exports